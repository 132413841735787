import { BrandDot } from '@/components/BrandDot'
import { Breadcrumb, BreadcrumbItem } from '@/components/Breadcrumb'
import { FeatherIcon } from '@/components/FeatherIcon'
import { SEO } from '@/components/SEO'
import { StackedCards } from '@/components/StackedCards'
import { useTheme } from '@/store'
import {
  SingleLookbookTemplateQuery,
  SingleLookbookTemplateQueryVariables,
} from '@/types/__generated__/SingleLookbookTemplateQuery'
import { extractNodes, fluidFontSize, scrimGradient } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Masonry from 'react-masonry-css'

export const query = graphql`
  query SingleLookbookTemplateQuery($id: String!, $otherIds: [String!]!) {
    contentfulLookbook(contentful_id: { eq: $id }) {
      name
      description {
        description
      }
      sections {
        ... on ContentfulLookbookProject {
          name
          projectLink {
            slug
          }
          featuredImages {
            contentful_id
            fluid(maxWidth: 512, quality: 85) {
              ...ContentfulFluid
            }
          }
        }
      }
    }

    otherLookbooks: allContentfulLookbook(
      filter: { contentful_id: { in: $otherIds } }
    ) {
      edges {
        node {
          slug
          name
          coverImage {
            fluid(
              maxWidth: 1024
              maxHeight: 576
              quality: 85
              resizingBehavior: FILL
            ) {
              ...ContentfulFluid
            }
          }
        }
      }
    }
  }
`

export type PageContext = SingleLookbookTemplateQueryVariables

type Props = {
  data: SingleLookbookTemplateQuery
  pageContext: PageContext
}

const SingleLookbookTemplate: React.FC<Props> = ({ data }) => {
  const theme = useTheme()
  const {
    name,
    description: { description },
    sections,
  } = data.contentfulLookbook
  const otherLookbooks = extractNodes(data.otherLookbooks)
  const masonryBreakpoints = {
    default: 2,
    // 768 equals to mqTwoCols above
    767: 1,
  }

  return (
    <>
      <SEO
        title={name}
        metaDescription={`Lookbook containing ${sections.reduce(
          (accumulator, section) => section.featuredImages.length + accumulator,
          0,
        )} images from ${sections.length} projects.`}
      />

      <Breadcrumb>
        <BreadcrumbItem href="/">Home</BreadcrumbItem>
        <BreadcrumbItem href="/portfolio/">Portfolio</BreadcrumbItem>
        <BreadcrumbItem href="/portfolio/lookbooks/">Lookbooks</BreadcrumbItem>
        <BreadcrumbItem>{name}</BreadcrumbItem>
      </Breadcrumb>

      <article>
        <header
          css={css`
            padding: 0 5%;
            max-width: 10em;
            margin: 0 auto;
            box-sizing: content-box;

            margin-top: 2em;
            margin-bottom: 2em;

            ${fluidFontSize(30, 48, 320, theme.vwImageCapWidth)};
            text-align: center;
          `}
        >
          <h1
            css={css`
              margin-bottom: 0.25em;

              color: ${theme.color.text_default};
              font-size: 1em;
              line-height: 1.1;
              letter-spacing: -0.03em;
            `}
          >
            {name}
            <BrandDot />
          </h1>
          <p
            css={css`
              color: ${theme.color.text_lighter};
              font-size: 0.5em;
              line-height: 1.3;
            `}
          >
            {description}
          </p>
        </header>

        {sections.map(({ name: projectName, featuredImages, projectLink }) => {
          const projectHref = `/portfolio/projects/${projectLink.slug}/`

          return (
            <section key={projectLink.slug} id={projectLink.slug}>
              <h2
                css={css`
                  padding: 0 5%;
                  max-width: ${theme.maxWidth};
                  margin: 0 auto;
                  box-sizing: content-box;
                  margin-bottom: 1rem;

                  color: ${theme.color.text_light};
                  font-weight: 400;

                  .project-text {
                    margin-bottom: 0.25rem;
                    font-style: italic;
                    font-size: ${theme.size[2]};
                  }

                  a {
                    font-size: ${theme.size[5]};
                    color: inherit;
                    font-weight: 700;
                    text-decoration: none;

                    &:hover {
                      text-decoration: underline;
                    }
                  }
                `}
              >
                <div className="project-text">Project:</div>
                <Link to={projectHref}>{projectName}</Link>
              </h2>

              <div
                css={css`
                  max-width: ${theme.maxWidth};
                  margin: 0 auto;
                `}
              >
                <Masonry
                  breakpointCols={masonryBreakpoints}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {featuredImages.map(({ fluid, contentful_id }) => (
                    <Img
                      key={contentful_id}
                      fluid={fluid}
                      alt=""
                      css={css`
                        border-radius: ${theme.radius.photo};
                        box-shadow: ${theme.elevation[2]};
                      `}
                    />
                  ))}
                </Masonry>
              </div>

              <div
                css={css`
                  max-width: ${theme.maxWidth};
                  margin: 0 auto;
                  box-sizing: content-box;
                  padding: 0 5%;

                  text-align: center;
                `}
              >
                <Link
                  to={projectHref}
                  css={css`
                    margin-top: 3rem;
                    margin-bottom: 12.5%;
                    padding: 0.75rem;
                    /* Optical adjustment due to svg having right padding */
                    padding-left: 1rem;
                    border: solid 1px ${theme.color.text_default};

                    display: inline-flex;
                    align-items: center;

                    color: ${theme.color.text_default};
                    text-decoration: none;
                    transition: all 0.1s ease-out;

                    &:hover {
                      background: ${theme.color.grays[8]};
                      color: ${theme.color.grays[0]};
                    }

                    svg {
                      margin-left: 0.25rem;
                      margin-bottom: -1%;
                    }
                  `}
                >
                  <span>View this project</span>
                  <FeatherIcon name="chevron-right" size={20} />
                </Link>
              </div>
            </section>
          )
        })}

        {otherLookbooks.length > 1 && (
          <footer
            css={css`
              margin-top: 6rem;
              background: ${theme.color.grays[1]};
              box-shadow: inset 0 2px 8px 0 rgba(${theme.color.shadow}, 0.1);
              padding: 6.25% 5%;
            `}
          >
            <div
              css={css`
                max-width: ${theme.maxWidth};
                margin: 0 auto;
              `}
            >
              <h2
                css={css`
                  color: ${theme.color.grays[7]};
                  ${fluidFontSize(24, 36, 320, theme.vwImageCapWidth)};
                  letter-spacing: -0.03em;
                  text-align: center;
                `}
              >
                View other lookbooks
              </h2>
              {otherLookbooks.map(lookbook => (
                <article
                  key={lookbook.slug}
                  css={css`
                    margin-top: 6.25%;
                  `}
                >
                  <Link to={`/portfolio/lookbooks/${lookbook.slug}/`}>
                    <StackedCards>
                      <>
                        <Img fluid={lookbook.coverImage.fluid} alt="" />
                        <div
                          css={css`
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            padding: 3.125%;
                            padding-top: 12.5%;

                            ${scrimGradient({
                              color: theme.color.grays[8],
                              direction: 'to top',
                              startAlpha: 0.64,
                            })}
                          `}
                        >
                          <h2
                            css={css`
                              position: relative;

                              ${fluidFontSize(
                                16,
                                30,
                                320,
                                theme.vwImageCapWidth,
                              )};
                              color: ${theme.color.grays[0]};
                              text-shadow: ${theme.textShadow};
                            `}
                          >
                            {lookbook.name}
                          </h2>
                        </div>
                      </>
                    </StackedCards>
                  </Link>
                </article>
              ))}
            </div>
          </footer>
        )}
      </article>
    </>
  )
}

export default SingleLookbookTemplate
